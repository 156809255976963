import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import HomeBanner from '../components/home/HomeBanner';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import { Link } from 'react-router-dom';
import Img1 from '../assets/images/why-dubai/Img1.webp';
import Img2 from '../assets/images/why-dubai/Img2.webp';
import Img3 from '../assets/images/why-dubai/Img3.webp';
import Img4 from '../assets/images/why-dubai/Img4.webp';
import Img5 from '../assets/images/why-dubai/Img5.webp';
import Img6 from '../assets/images/why-dubai/Img6.webp';
import Img7 from '../assets/images/why-dubai/Img7.webp';
import { CheckLg } from 'react-bootstrap-icons';
import Icon4 from '../assets/images/why-800-homes/house.png';
import BannerImg from '../assets/images/why-dubai/BannerWhy.webp';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import BgMiddle from '../assets/images/why-dubai/Img2.webp';

const WhyDubai = () => {
  const [counterOn, setCounterOn] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
            <>
        <Helmet>
        <meta charSet="utf-8" />
        <title> Why Dubai? | Invest in the Fastest-Growing Property Market with 800 Homes </title>
        <meta name="description" content="Find out why Dubai is the best choice to invest in real estate. 800 Homes explains the tax advantages, high ROI, and investor-friendly policies to invest in dubai real estate." />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Why Dubai? | Invest in the Fastest-Growing Property Market with 800 Homes" />
        <meta property="og:description" content="Find out why Dubai is the best choice to invest in real estate. 800 Homes explains the tax advantages, high ROI, and investor-friendly policies to invest in dubai real estate." />
        <meta property="og:url" content="https://800homes.ae/" />
        <meta property="og:site_name" content="800homes" />
        <meta property="og:image" content="https://800homes.ae/og.webp" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://800homes.ae/og.webp" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Find out why Dubai is the best choice to invest in real estate. 800 Homes explains the tax advantages, high ROI, and investor-friendly policies to invest in dubai real estate." />
        <meta name="twitter:title" content="Why Dubai? | Invest in the Fastest-Growing Property Market with 800 Homes" />
        <meta name="twitter:image" content="https://800homes.ae/og.webp"/>
        <link rel="canonical" href="https://800homes.ae/why-dubai"/>
        </Helmet>

    <section id='website-responsive' className="container-fluid px-0">
    <section id='website-responsive' className="container-fluid bg-common-banner bg-home vh-100 px-0" style={{backgroundImage:`url(${BannerImg})`}}>
    <Header/>
    <div className="py-md-5 mt-5 my-md-4" />
    <div id='common-banner' className="col-10 mx-auto ps-md-3">
      <div className="fw-500 text-white fs-home-banner text-uppercase lh-sm" data-aos="fade-up">
      The Ultimate Destination  
        <br className="" />
        for Luxury Living 
      </div>
      <div className="text-white fs-sub-home-banner fw-400 pt-2 pt-md-3" data-aos="fade-up">
      Where Dreams Meet Reality
        </div>
        </div>    
    </section>

    <section className="py-md-5 py-4" />

    <section className="container-fluid">
      <div className="row justify-content-center align-items-center gy-5">
        <div className="col-11 col-md-5">
          <div className="fs-ss-30 fw-600 text-white pb-4" data-aos="fade-up">
          Why Choose Dubai for <br className="d-none d-md-block" /> Your Next Home?  
          </div>

          <div className="pb-4 text-white fs-ss-18 fw-300 col-md-10 lh-1-7em" data-aos="fade-up">
          Whether you're drawn to a luxurious lifestyle, dynamic business environment, or cultural diversity, there is something to offer for everyone. Real estate brokers in Dubai help you find the right property tailored to your needs and preferences. With no personal income tax, a stable economy, and a focus on sustainable growth, it is an ideal place to call home.  
          </div>
          <div className="text-white fs-ss-18 fw-400" data-aos="fade-up">
            <Link to="/contact-us" className='text-decoration-none px-4 py-ss-2 text-white bg-ss-primary bg-hover-ss-primary rounded-5'>
           Inquire Now
            </Link>
          </div>
        </div>
        <div className="col-11 col-md-5 px-0" >
          <img src={Img1} className='w-100' data-aos="fade-up" alt="" />
        </div>
      </div>
    </section>

     <section className="py-md-5 py-4" />
    {/* Mobile Space */}
    <section className="pt-4 pt-md-0" />

     <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}> 
    <section id='numbers' className="container-lg">
      <div className="row justify-content-center text-center px-md-5 gy-4">        
      <div className="pb-2 pb-md-4 text-white fs-ss-35 fw-500" data-aos="fade-up">
        Dubai Real Estate Transactions
        </div>
        <div className="col-md border-white border-end border-1">
          <div className="fs-ss-40 text-ss-primary fw-600">
          {counterOn && <CountUp start={0} end={17503} duration={4} delay={0}/>}
          </div>
          <div className="fs-ss-18 text-white">
          Total Transactions in September 
          </div>
        </div>

        <div className="col-md border-white border-end border-1">
          <div className="fs-ss-40 text-ss-primary fw-600">
          {counterOn && <CountUp start={0} end={43} duration={4} delay={0}/>}.57B
          </div>
          <div className="fs-ss-18 text-white">
          Property Sales in September 
          </div>
        </div>

        <div className="col-md">
          <div className="fs-ss-40 text-ss-primary fw-600">
          {counterOn && <CountUp start={0} end={14118} duration={4} delay={0}/>}
          </div>
          <div className="fs-ss-18 text-white">
          Units Sold in September 
          </div>
        </div>

        {/* <div className="col-md">
          <div className="fs-ss-40 text-ss-primary fw-600">
          {counterOn && <CountUp start={0} end={1473} duration={2} delay={0}/>}
          </div>
          <div className="fs-ss-18 text-white">
          Buildings Sold in July 
          </div>
        </div> */}

      </div>
    </section>
    </ScrollTrigger>

      <section className="py-md-5 py-4" />
    {/* Mobile Space */}
    <section className="pt-4 pt-md-0" />

      <section id='bg-middle-banner' className="container-fluid bg-middle-banner py-5 bg-fixed" style={{backgroundImage:`url(${BgMiddle})`}}>
      <div className="row justify-content-evenly py-md-5">
        <div className="col-11 col-md-6 mx-auto bg-middle-banner-color text-center p-5 my-md-5" data-aos="fade-up">
        <div className="pb-2 text-white fs-ss-35 fw-600">
        Luxury at Every Corner  
          </div>
          <div className="text-white fs-ss-22 fw-300 lh-1-7em">
          Dubai is a city where luxury is a way of life. Every aspect of it radiates elegance, from its stunning skyline to the stylish design of private homes. Imagine living in a penthouse with panoramic city views or a villa with exclusive beaches.  
          </div>
        </div>
      </div>
    </section>

    <section className="py-md-5 py-4" />
    {/* Mobile Space */}
    <section className="pt-4 pt-md-0" />

      <section className="container-fluid">
      <div className="row justify-content-evenly align-items-center gy-5">
      <div className="col-11 col-md-5 px-0 d-none d-md-block" >
          <img src={Img3} className='w-100' data-aos="fade-up" alt="" />
        </div>
        <div className="col-11 col-md-5">
          <div className="fs-ss-30 fw-600 text-white pb-4" data-aos="fade-up">
          Prime Real Estate Market  
          </div>
          <div className="pb-4 text-white fs-ss-18 fw-300 col-md-10 lh-1-7em" data-aos="fade-up">
          <div className="d-flex pb-2 pb-md-3">
            <div className="">
              <CheckLg className='fs-ss-30 text-ss-primary me-3'/>
            </div>
            <div className="lh-1-7em">
            High ROI Potential
            </div>
          </div>

          <div className="d-flex pb-2 pb-md-3">
            <div className="">
              <CheckLg className='fs-ss-30 text-ss-primary me-3'/>
            </div>
            <div className="lh-1-7em">
            Global Business Hub
            </div>
          </div>

          <div className="d-flex pb-2 pb-md-3">
            <div className="">
              <CheckLg className='fs-ss-30 text-ss-primary me-3'/>
            </div>
            <div className="lh-1-7em">
            200+ Nationalities
            </div>
          </div>

          <div className="d-flex pb-2 pb-md-3">
            <div className="">
              <CheckLg className='fs-ss-30 text-ss-primary me-3'/>
            </div>
            <div className="lh-1-7em">
            #1 in MENA for Real Estate
            </div>
          </div>
          </div>
          <div className="text-white fs-ss-18 fw-400" data-aos="fade-up">
            <Link to="/contact-us" className='text-decoration-none px-4 py-ss-2 text-white bg-ss-primary bg-hover-ss-primary rounded-5'>
            Get in Touch
            </Link>
          </div>
        </div>
        <div className="col-11 col-md-5 px-0 d-block d-md-none" >
          <img src={Img3} className='w-100' data-aos="fade-up" alt="" />
        </div>
      </div>
    </section>

    <section className="py-md-5 py-4 my-md-2" />
    {/* Mobile Space */}
    <section className="pt-4 pt-md-0" />

    <section className="container-fluid">
      <div className="row justify-content-evenly gy-4 text-md-center">
        <div className="col-11 col-md-12">
        <div className="fs-ss-30 fw-600 text-white pb-3" data-aos="fade-up">
        Exclusive Perks  
          </div>
          <div className=" text-white fs-ss-18 lh-1-7em fw-300 col-md-8 mx-auto" data-aos="fade-up">
          Living in this city means enjoying many exclusive benefits. The city is renowned for its luxury shopping destinations, which host over 1,200 stores. Moreover, its strategic location offers easy travel to other European, Asian, and African luxury destinations. Along with consistently ranking among the top cities worldwide for its exceptional infrastructure, its appeal as a top destination for luxury living is unsurpassable.  
          </div>
          <div className="pt-4 text-white fs-ss-18 fw-400" data-aos="fade-up">
            <Link to="/contact-us" className='text-decoration-none px-4 py-ss-2 text-white bg-ss-primary bg-hover-ss-primary rounded-5'>
            Inquire Now
            </Link>
          </div>
        </div>
      </div>
    </section>

    <section className="py-md-5 py-4 my-md-2" />
    {/* Mobile Space */}
    <section className="pt-4 pt-md-0" />

    <section className="container-fluid">
      <div className="row justify-content-center align-items-center gy-4">
      <div className="col-11 col-md-4 px-0 pb-4 pb-md-0" >
      <div className="col-11 col-md-10 ms-md-auto mx-auto" >
      <div className="fs-ss-48 fw-600 text-white pb-4 lh-sm" data-aos="fade-up">
      A Safe and <br className="d-none d-md-block" /> Family-Friendly Environment  
          </div>
          <div className="pb-md-4 text-white fs-ss-18 fw-300 lh-1-7em" data-aos="fade-up">
          Renowned for luxury, this city is equally committed to providing a safe, family-oriented atmosphere. Consistently ranked among the safest in the world, it offers families unmatched security and peace of mind. With excellent schools, parks, and recreational facilities, it’s an ideal place for families to thrive. 
          </div>
          </div>
          </div>
          <div className="col-11 col-md-6">   
          <div className="row justify-content-center gy-4 px-1">
          <div className="col-6 col-md-5 " data-aos="fade-up">
              <img src={Img4} className='w-100' alt="" />
            </div>

            <div className="col-6 col-md-5 " data-aos="fade-up">
              <img src={Img5} className='w-100' alt="" />
            </div>

            <div className="col-6 col-md-5 " data-aos="fade-up">
              <img src={Img6} className='w-100' alt="" />
            </div>

            <div className="col-6 col-md-5 " data-aos="fade-up">
              <img src={Img7} className='w-100' alt="" />
            </div>
          </div>
        </div>
      </div>
      </section>

    <section className="py-md-5 py-4" />

    <Footer/>
    </section>
    </>
  );
}

export default WhyDubai;
