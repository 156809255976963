import React, { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Img1 from '../../assets/images/listing/listing6/1.webp';
import Img2 from '../../assets/images/listing/listing6/2.webp';
import Img3 from '../../assets/images/listing/listing6/3.webp';
import Img4 from '../../assets/images/listing/listing6/4.webp';
import Img5 from '../../assets/images/listing/listing6/5.webp';
import Img6 from '../../assets/images/listing/listing6/6.webp';
import Img7 from '../../assets/images/listing/listing6/7.webp';
import Img8 from '../../assets/images/listing/listing6/8.webp';
import Img9 from '../../assets/images/listing/listing6/9.webp';
import Img10 from '../../assets/images/listing/listing6/10.webp';
import Img11 from '../../assets/images/listing/listing6/11.webp';
import Img12 from '../../assets/images/listing/listing6/12.webp';
import Img13 from '../../assets/images/listing/listing6/13.webp';
import Img14 from '../../assets/images/listing/listing6/14.webp';
import Img15 from '../../assets/images/listing/listing6/15.webp';
import Img16 from '../../assets/images/listing/listing6/16.webp';
import Img17 from '../../assets/images/listing/listing6/17.webp';
import Img18 from '../../assets/images/listing/listing6/18.webp';
import Img19 from '../../assets/images/listing/listing6/19.webp';

import Header from "../../components/layout/Header";

import Footer from "../../components/layout/Footer";
import DetailsOfListing from "../../components/listing/individual/DetailsOfListing";
import InquiryOfListing from "../../components/listing/individual/InquiryOfListing";
import ShareSocialMediaIcons from "../../components/listing/individual/ShareSocialMediaIcons";
import { Link } from "react-router-dom";
import FeaturesAndAmenities from "../../components/listing/individual/FeaturesAndAmenities";
import ListingDetailsSection from "../../components/listing/individual/ListingDetailsSection";

const Listing7 = (props) => {
  
  const images = [
    {
      original: Img1,
      thumbnail: Img1,
    },
    {
      original: Img2,
      thumbnail: Img2,      
    },
    {
      original: Img3,
      thumbnail: Img3,      
    },
    {
      original: Img4,
      thumbnail: Img4,      
    },
    {
      original: Img5,
      thumbnail: Img5,      
    },
    {
      original: Img6,
      thumbnail: Img6,      
    },
    {
      original: Img7,
      thumbnail: Img7,      
    },
    {
      original: Img8,
      thumbnail: Img8,      
    },
    {
      original: Img9,
      thumbnail: Img9,      
    },
    {
      original: Img10,
      thumbnail: Img10,      
    },
    {
      original: Img11,
      thumbnail: Img11,      
    },
    {
      original: Img12,
      thumbnail: Img12,      
    },
    {
      original: Img13,
      thumbnail: Img13,      
    },
    {
      original: Img14,
      thumbnail: Img14,      
    },
    {
      original: Img15,
      thumbnail: Img15,      
    },
    {
      original: Img16,
      thumbnail: Img16,      
    },
    {
      original: Img17,
      thumbnail: Img17,      
    },
    {
      original: Img18,
      thumbnail: Img18,      
    },
    {
      original: Img19,
      thumbnail: Img19,      
    },
    
  ];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Header/>
    <section id="website-responsive" className="container-fluid py-5 border-top border-1 border-white">
      <div className="row justify-content-evenly">
        <div className="col-11 col-md-7 px-0">
        <div className="">
          <div className="" data-aos="fade-up">
          <ImageGallery className="transition" items={images} />
          </div>
          <DetailsOfListing
          Title="APARTMENT FOR SALE IN NESBA, ALJADA"
          Title2="Premier Living | 2BR | Aljada by Arada"
          Price="1,484,000"
          Location="Sharjah"
          Category="Apartment"
          Beds="2"
          Baths="3"
          Area="1,194 sqft / 111 sqm"
          FeaturesAndAmenities={<>
            <FeaturesAndAmenities Title="Study" />
            <FeaturesAndAmenities Title="Central A/C" />
            <FeaturesAndAmenities Title="Balcony" />
            <FeaturesAndAmenities Title="Shared Pool" />
            <FeaturesAndAmenities Title="Shared Spa" />
            <FeaturesAndAmenities Title="Security" />
            <FeaturesAndAmenities Title="Covered Parking" />
            <FeaturesAndAmenities Title="Built in Wardrobes" />
            <FeaturesAndAmenities Title="Kitchen Appliances" />
            <FeaturesAndAmenities Title="Shared Gym" />
            <FeaturesAndAmenities Title="Lobby in Building" />
            <FeaturesAndAmenities Title="Children's Pool" />
            <FeaturesAndAmenities Title="Children's Play Area" />
            <FeaturesAndAmenities Title="Barbecue Area" />  
            </>}
          Description={<>
          Apartment for sale in Nesba, Aljada
          <div className="py-2" />
          Live: Aljada is set to redefine Sharjah living with its vibrant, all-encompassing urban environment. Experience a city where excitement and endless possibilities await at every turn. Enjoy the perfect blend of dynamic city life and serene tranquility as you discover a place where every day brings new adventures and opportunities.
          <div className="py-2" />
          Play: Immerse yourself in unparalleled leisure and entertainment. With over 5 kilometers of beautifully designed parks, your recreational options are vast and varied. Explore Madar, Aljada’s premier leisure destination, featuring exhilarating attractions and expansive green spaces. The East Boulevard offers a diverse array of retail and dining options, creating a lively social hub for residents and visitors alike
          <div className="py-2" />
          Work: Propel your business to new heights in Arada’s central business district, the heart of commercial activity in one of the region’s fastest-growing cities. This state-of-the-art hub is tailored for every business need, from global corporations to emerging start-ups. Benefit from cutting-edge technology, lush green areas, and stunning water features, all complemented by extensive F&B outlets and inviting outdoor seating that seamlessly blend work and leisure.
          <div className="py-2" />
          Discover Aljada—the ultimate fusion of living, playing, and working in a city where every moment is filled with excitement and innovation.
          </>}
          ListingDetailsSection={<>
          <ListingDetailsSection Title1="Property Type" Title2="Apartment" />          
          <ListingDetailsSection Title1="Property Size" Title2="1,194 sqft / 111 sqm" />          
          <ListingDetailsSection Title1="Bedrooms" Title2="2" />          
          <ListingDetailsSection Title1="Bathrooms" Title2="3" />     
          </>}
          />
      </div>
        </div>
        <div className="col-11 col-md-3">
          <div className="border-white border-1 border p-4 mb-4 sticky-top">
          <InquiryOfListing />
          <ShareSocialMediaIcons
          url_Email="https://800homes.eacademe.org/apartment-for-sale-sharjah-aljada-nesba-12387671"
          url_Whatsapp="https://800homes.eacademe.org/apartment-for-sale-sharjah-aljada-nesba-12387671"
          url_Facebook="https://800homes.eacademe.org/apartment-for-sale-sharjah-aljada-nesba-12387671"
          url_Linkedin="https://800homes.eacademe.org/apartment-for-sale-sharjah-aljada-nesba-12387671"
          />
          </div>
        </div>        
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default Listing7 