import React, { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Img1 from '../../assets/images/listing/listing2/1.webp';
import Img2 from '../../assets/images/listing/listing2/2.webp';
import Img3 from '../../assets/images/listing/listing2/3.webp';
import Img4 from '../../assets/images/listing/listing2/4.webp';
import Img5 from '../../assets/images/listing/listing2/5.webp';
import Img6 from '../../assets/images/listing/listing2/6.webp';
import Img7 from '../../assets/images/listing/listing2/7.webp';
import Img8 from '../../assets/images/listing/listing2/8.webp';
import Img9 from '../../assets/images/listing/listing2/9.webp';
import Img10 from '../../assets/images/listing/listing2/10.webp';
import Img11 from '../../assets/images/listing/listing2/11.webp';
import Img12 from '../../assets/images/listing/listing2/12.webp';
import Img13 from '../../assets/images/listing/listing2/13.webp';

import Header from "../../components/layout/Header";

import Footer from "../../components/layout/Footer";
import DetailsOfListing from "../../components/listing/individual/DetailsOfListing";
import InquiryOfListing from "../../components/listing/individual/InquiryOfListing";
import ShareSocialMediaIcons from "../../components/listing/individual/ShareSocialMediaIcons";
import { Link } from "react-router-dom";
import FeaturesAndAmenities from "../../components/listing/individual/FeaturesAndAmenities";
import ListingDetailsSection from "../../components/listing/individual/ListingDetailsSection";

const Listing2 = (props) => {
  
  const images = [
    {
      original: Img1,
      thumbnail: Img1,
    },
    {
      original: Img2,
      thumbnail: Img2,      
    },
    {
      original: Img3,
      thumbnail: Img3,      
    },
    {
      original: Img4,
      thumbnail: Img4,      
    },
    {
      original: Img5,
      thumbnail: Img5,      
    },
    {
      original: Img6,
      thumbnail: Img6,      
    },
    {
      original: Img7,
      thumbnail: Img7,      
    },
    {
      original: Img8,
      thumbnail: Img8,      
    },
    {
      original: Img9,
      thumbnail: Img9,      
    },
    {
      original: Img10,
      thumbnail: Img10,      
    },
    {
      original: Img11,
      thumbnail: Img11,      
    },
    {
      original: Img12,
      thumbnail: Img12,      
    },
    {
      original: Img13,
      thumbnail: Img13,      
    },
  ];
  
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

  return (
    <>
    <Header/>
    <section id="website-responsive" className="container-fluid py-5 border-top border-1 border-white">
      <div className="row justify-content-evenly">
        <div className="col-11 col-md-7 px-0">
        <div className="">
          <div className="" data-aos="fade-up">
          <ImageGallery className="transition" items={images} />
          </div>
          <DetailsOfListing
          Title="TOWNHOUSE FOR SALE IN SARO, MASAAR"
          Title2="Tranquil Sanctuary | 4BR TH | Payment Plan"
          Price="2,802,000"
          Location="Sharjah"
          Category="Townhouse"
          Beds="4 + Maid"
          Baths="4"
          Area="1,916 sqft / 178 sqm"
          FeaturesAndAmenities={<>
            <FeaturesAndAmenities Title="Study" />
            <FeaturesAndAmenities Title="Central A/C" />
            <FeaturesAndAmenities Title="Balcony" />
            <FeaturesAndAmenities Title="Shared Pool" />
            <FeaturesAndAmenities Title="Shared Spa" />
            <FeaturesAndAmenities Title="Security" />
            <FeaturesAndAmenities Title="Built in Wardrobes" />
            <FeaturesAndAmenities Title="Kitchen Appliances" />
            <FeaturesAndAmenities Title="Shared Gym" />
            <FeaturesAndAmenities Title="Maids Room" />
            <FeaturesAndAmenities Title="Children's Pool" />
            <FeaturesAndAmenities Title="Private Garden" />
            <FeaturesAndAmenities Title="Children's Play Area" />
            <FeaturesAndAmenities Title="Barbecue Area" />  
            </>}
          Description={<>
          Townhouse for sale in Saro, Masaar
          <div className="py-2" />
          Step into Masaar and embrace a haven of serenity where every breath you take brings a sense of calm and renewal. Nestled just minutes from the city yet a world apart, Masaar offers a perfect balance of peace and convenience. This upscale, forested community is divided into seven gated neighborhoods, each seamlessly connected by a lush ‘green spine’ featuring over 50,000 trees.
          <div className="py-2" />
          Masaar presents a range of homes, from charming two-bedroom townhouses to grand five-bedroom signature villas, all equipped with state-of-the-art smart home features. Each residence provides direct access to expansive green parks, ensuring that nature is always within reach.
          <div className="py-2" />
          Designed for those who cherish an active, healthy lifestyle, Masaar is a paradise for outdoor enthusiasts. The community boasts a central hub, a community center, and 13 kilometers of dedicated cycling and jogging trails. Enjoy tranquil moments in quiet zones or engage in sports with facilities for football, tennis, basketball, and padel tennis. Unique installations and thoughtfully designed spaces throughout the green spine foster a vibrant, interactive environment.
          <div className="py-2" />
          Discover your new address at Masaar, where every day offers a harmonious blend of natural beauty and modern comfort.
          </>}
          ListingDetailsSection={<>
            <ListingDetailsSection Title1="Property Type" Title2="Townhouse" />          
            <ListingDetailsSection Title1="Property Size" Title2="1,916 sqft / 178 sqm" />          
            <ListingDetailsSection Title1="Bedrooms" Title2="4 + Maid" />          
            <ListingDetailsSection Title1="Bathrooms" Title2="4" />     
            </>}          
          />
      </div>
        </div>
        <div className="col-11 col-md-3">
          <div className="border-white border-1 border p-4 mb-4 sticky-top">
          <InquiryOfListing/>
          <ShareSocialMediaIcons
          url_Email="https://800homes.eacademe.org/townhouse-for-sale-sharjah-tilal-city-masaar-saro-12387423"
          url_Whatsapp="https://800homes.eacademe.org/townhouse-for-sale-sharjah-tilal-city-masaar-saro-12387423"
          url_Facebook="https://800homes.eacademe.org/townhouse-for-sale-sharjah-tilal-city-masaar-saro-12387423"
          url_Linkedin="https://800homes.eacademe.org/townhouse-for-sale-sharjah-tilal-city-masaar-saro-12387423"
          />
          </div>
        </div>        
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default Listing2