import React from "react"

const FeaturesAndAmenities = (props) =>{

    return (
    <>
    <div className="col-11 col-md-4 border border-1 border-white py-3 ps-4 pe-1 fw-300">
      {props.Title}
    </div>
    </>
    )
}

export default FeaturesAndAmenities