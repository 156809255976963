import React from 'react';
import { Link } from "react-router-dom"
import Navbar from 'react-bootstrap/Navbar';
import HeaderLogo from '../../assets/images/800home/Logo.webp';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useLocation } from "react-router-dom";
import OneNavLink from './OneNavLink';

const MobileHeader = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <>
    <section className="container-fluid bg-ss-secondary">
      <Navbar expand="lg" className="">
        <div className='container-fluid gx-0'>
            <Navbar.Brand className='navbar-brand ms-2 py-2'>
              
              <Link
            className=""
            to="/"
            >
            <img
                src={HeaderLogo}
                alt="Logo"
                className="header-logo"
            />
            </Link>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} className='border-0 rounded-0 toggle-icon bg-white fs-ss-12 py-2 rounded-2'/>

            <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-xl`}
            aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
            placement="start"
            className="navbar-offcanvas"
            >
            <Offcanvas.Header closeButton className=''>

                <Offcanvas.Title className='' id={`offcanvasNavbarLabel-expand-xl`}>
                    <Link
                    className=""
                    to="/"
                    >
                    <img
                        src={HeaderLogo}
                        alt="Logo"
                        className="header-logo"
                    />
                    </Link>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <ul className="navbar-nav ms-auto">

            <OneNavLink 
    NavItem="Why 800 Homes"
    Link="/why-800-homes"
    />

        <OneNavLink 
    NavItem="Leadership"
    Link="/leadership"
    />
        <OneNavLink 
    NavItem="Why Dubai"
    Link="/why-dubai"
    />
    
    <OneNavLink 
    NavItem="Buy Property"
    Link="/properties-for-sale"
    />

            <OneNavLink 
    NavItem="Sell Property"
    Link="/sell-property"
    />

        <OneNavLink 
    NavItem="FAQs"
    Link="/faqs"
    />

            <OneNavLink 
    NavItem="Careers"
    Link="/careers"
    />
            {/* <OneNavLink 
    NavItem="Latest News"
    Link="/blogs"
    /> */}
        <OneNavLink 
    NavItem="Contact Us"
    Link="/contact-us"
    />
            </ul>

            </Offcanvas.Body>
            </Navbar.Offcanvas>
            </div>        
            </Navbar>
    </section>
    </>
  )
}

export default MobileHeader