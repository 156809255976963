import React, { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Img1 from '../../assets/images/listing/listing17/1.webp';
import Img2 from '../../assets/images/listing/listing17/2.webp';
import Img3 from '../../assets/images/listing/listing17/3.webp';
import Img4 from '../../assets/images/listing/listing17/4.webp';
import Img5 from '../../assets/images/listing/listing17/5.webp';
import Img6 from '../../assets/images/listing/listing17/6.webp';
import Img7 from '../../assets/images/listing/listing17/7.webp';
import Img8 from '../../assets/images/listing/listing17/8.webp';
import Img9 from '../../assets/images/listing/listing17/9.webp';
import Img10 from '../../assets/images/listing/listing17/10.webp';
import Img11 from '../../assets/images/listing/listing17/11.webp';
import Img12 from '../../assets/images/listing/listing17/12.webp';
import Img13 from '../../assets/images/listing/listing17/13.webp';
import Img14 from '../../assets/images/listing/listing17/14.webp';
import Img15 from '../../assets/images/listing/listing17/15.webp';
import Img16 from '../../assets/images/listing/listing17/16.webp';
import Img17 from '../../assets/images/listing/listing17/17.webp';
import Img18 from '../../assets/images/listing/listing17/18.webp';

import Header from "../../components/layout/Header";

import Footer from "../../components/layout/Footer";
import DetailsOfListing from "../../components/listing/individual/DetailsOfListing";
import InquiryOfListing from "../../components/listing/individual/InquiryOfListing";
import ShareSocialMediaIcons from "../../components/listing/individual/ShareSocialMediaIcons";
import { Link } from "react-router-dom";
import FeaturesAndAmenities from "../../components/listing/individual/FeaturesAndAmenities";
import ListingDetailsSection from "../../components/listing/individual/ListingDetailsSection";

const Listing15 = (props) => {
  
  const images = [
    {
      original: Img1,
      thumbnail: Img1,
    },
    {
      original: Img2,
      thumbnail: Img2,      
    },
    {
      original: Img3,
      thumbnail: Img3,      
    },
    {
      original: Img4,
      thumbnail: Img4,      
    },
    {
      original: Img5,
      thumbnail: Img5,      
    },
    {
      original: Img6,
      thumbnail: Img6,      
    },
    {
      original: Img7,
      thumbnail: Img7,      
    },
    {
      original: Img8,
      thumbnail: Img8,      
    },
    {
      original: Img9,
      thumbnail: Img9,      
    },
    {
      original: Img10,
      thumbnail: Img10,      
    },
    {
      original: Img11,
      thumbnail: Img11,      
    },
    {
      original: Img12,
      thumbnail: Img12,      
    },
    {
      original: Img13,
      thumbnail: Img13,      
    },
    {
      original: Img14,
      thumbnail: Img14,      
    },
    {
      original: Img15,
      thumbnail: Img15,      
    },
    {
      original: Img16,
      thumbnail: Img16,      
    },
    {
      original: Img17,
      thumbnail: Img17,      
    },
    {
      original: Img18,
      thumbnail: Img18,      
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Header/>
    <section id="website-responsive" className="container-fluid py-5 border-top border-1 border-white">
      <div className="row justify-content-evenly">
        <div className="col-11 col-md-7 px-0">
        <div className="">
          <div className="" data-aos="fade-up">
          <ImageGallery className="transition" items={images} />
          </div>
          <DetailsOfListing
          Title="APARTMENT FOR SALE IN NEST 6, NEST"
          Title2="Vibrant Community | Studio | Nest-Aljada by Arada"
          Price="314,000"
          Location="Sharjah"
          Category="Apartment"
          Beds="Studio"
          Baths="1"
          Area="241 sqft / 22 sqm"
          FeaturesAndAmenities={<>
            <FeaturesAndAmenities Title="Central A/C" />
            <FeaturesAndAmenities Title="Balcony" />
            <FeaturesAndAmenities Title="Shared Pool" />
            <FeaturesAndAmenities Title="Shared Spa" />
            <FeaturesAndAmenities Title="Security" />
            <FeaturesAndAmenities Title="Built in Wardrobes" />
            <FeaturesAndAmenities Title="Shared Gym" />
            <FeaturesAndAmenities Title="Lobby in Building" />
            <FeaturesAndAmenities Title="Children's Play Area" />
            <FeaturesAndAmenities Title="Barbecue Area" />  
            </>}
          Description={<>
          Discover Nest, Arada’s latest student accommodation development, featuring 12 meticulously designed buildings in the vibrant Aljada community. For the first time, students can enjoy modern living spaces equipped with essential facilities, including laundry rooms, shared TV lounges, study areas, and collaborative study rooms.
          <div className="py-2" />
          Nestled just a 10-minute walk from the educational epicenter of Sharjah University City, this development offers unparalleled access to advanced learning environments and smart technology-enabled spaces tailored for students.
          <div className="py-2" />
          The community is thoughtfully designed with outdoor spaces that provide shade and promote airflow, ideal for the summer months. Internal courtyards serve as social hubs, while residents can take advantage of a range of amenities, including an extreme sports center, the largest edutainment center featuring virtual reality, a science museum, driving schools, and an exciting adventure golf course.
          <div className="py-2" />
          With a total of 1,288 fully furnished units and 2,600 bed spaces, Nest combines comfort with convenience. Enjoy art studios, a music hall, a library set amidst scenic running tracks, and a welcoming central hall—all designed by renowned architects Woods Bagot. Plus, students benefit from free public transport to university city.
          <div className="py-2" />
          Key Highlights: <br />
          Prime location <br />
          Unique design and facilities <br />
          Fully furnished units <br />
          Educational facilities at its heart
          <div className="py-2" />
          Experience student living redefined at Nest!

          </>}
          ListingDetailsSection={<>
          <ListingDetailsSection Title1="Property Type" Title2="Apartment" />          
          <ListingDetailsSection Title1="Property Size" Title2="241 sqft / 22 sqm" />          
          <ListingDetailsSection Title1="Bedrooms" Title2="Studio" />          
          <ListingDetailsSection Title1="Bathrooms" Title2="1" />     
          </>}
          />
      </div>
        </div>
        <div className="col-11 col-md-3">
          <div className="border-white border-1 border p-4 mb-4 sticky-top">
          <InquiryOfListing />
          <ShareSocialMediaIcons
          url_Email="https://800homes.eacademe.org/apartment-for-sale-sharjah-aljada-nest-nest-6"
          url_Whatsapp="https://800homes.eacademe.org/apartment-for-sale-sharjah-aljada-nest-nest-6"
          url_Facebook="https://800homes.eacademe.org/apartment-for-sale-sharjah-aljada-nest-nest-6"
          url_Linkedin="https://800homes.eacademe.org/apartment-for-sale-sharjah-aljada-nest-nest-6"
          />
          </div>
        </div>        
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default Listing15 