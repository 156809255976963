import React, { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Img1 from '../../assets/images/listing/listing11/1.webp';
import Img2 from '../../assets/images/listing/listing11/2.webp';
import Img3 from '../../assets/images/listing/listing11/3.webp';
import Img4 from '../../assets/images/listing/listing11/4.webp';
import Img5 from '../../assets/images/listing/listing11/5.webp';
import Img6 from '../../assets/images/listing/listing11/6.webp';
import Img7 from '../../assets/images/listing/listing11/7.webp';
import Img8 from '../../assets/images/listing/listing11/8.webp';
import Img9 from '../../assets/images/listing/listing11/9.webp';
import Img10 from '../../assets/images/listing/listing11/10.webp';
import Img11 from '../../assets/images/listing/listing11/11.webp';
import Img12 from '../../assets/images/listing/listing11/12.webp';
import Img13 from '../../assets/images/listing/listing11/13.webp';
import Img14 from '../../assets/images/listing/listing11/14.webp';
import Img15 from '../../assets/images/listing/listing11/15.webp';
import Img16 from '../../assets/images/listing/listing11/16.webp';
import Img17 from '../../assets/images/listing/listing11/17.webp';
import Img18 from '../../assets/images/listing/listing11/18.webp';
import Img19 from '../../assets/images/listing/listing11/19.webp';
import Img20 from '../../assets/images/listing/listing11/20.webp';
import Img21 from '../../assets/images/listing/listing11/21.webp';
import Img22 from '../../assets/images/listing/listing11/22.webp';
import Img23 from '../../assets/images/listing/listing11/23.webp';
import Img24 from '../../assets/images/listing/listing11/24.webp';
import Img25 from '../../assets/images/listing/listing11/25.webp';

import Header from "../../components/layout/Header";

import Footer from "../../components/layout/Footer";
import DetailsOfListing from "../../components/listing/individual/DetailsOfListing";
import InquiryOfListing from "../../components/listing/individual/InquiryOfListing";
import ShareSocialMediaIcons from "../../components/listing/individual/ShareSocialMediaIcons";
import { Link } from "react-router-dom";
import FeaturesAndAmenities from "../../components/listing/individual/FeaturesAndAmenities";
import ListingDetailsSection from "../../components/listing/individual/ListingDetailsSection";

const Listing11 = (props) => {
  
  const images = [
    {
      original: Img1,
      thumbnail: Img1,
    },
    {
      original: Img2,
      thumbnail: Img2,      
    },
    {
      original: Img3,
      thumbnail: Img3,      
    },
    {
      original: Img4,
      thumbnail: Img4,      
    },
    {
      original: Img5,
      thumbnail: Img5,      
    },
    {
      original: Img6,
      thumbnail: Img6,      
    },
    {
      original: Img7,
      thumbnail: Img7,      
    },
    {
      original: Img8,
      thumbnail: Img8,      
    },
    {
      original: Img9,
      thumbnail: Img9,      
    },
    {
      original: Img10,
      thumbnail: Img10,      
    },
    {
      original: Img11,
      thumbnail: Img11,      
    },
    {
      original: Img12,
      thumbnail: Img12,      
    },
    {
      original: Img13,
      thumbnail: Img13,      
    },
    {
      original: Img14,
      thumbnail: Img14,      
    },
    {
      original: Img15,
      thumbnail: Img15,      
    },
    {
      original: Img16,
      thumbnail: Img16,      
    },
    {
      original: Img17,
      thumbnail: Img17,      
    },
    {
      original: Img18,
      thumbnail: Img18,      
    },
    {
      original: Img19,
      thumbnail: Img19,      
    },
    {
      original: Img20,
      thumbnail: Img20,      
    },
    {
      original: Img21,
      thumbnail: Img21,      
    },
    {
      original: Img22,
      thumbnail: Img22,      
    },
    {
      original: Img23,
      thumbnail: Img23,      
    },
    {
      original: Img24,
      thumbnail: Img24,      
    },
    {
      original: Img25,
      thumbnail: Img25,
    }

  ];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Header/>
    <section id="website-responsive" className="container-fluid py-5 border-top border-1 border-white">
      <div className="row justify-content-evenly">
        <div className="col-11 col-md-7 px-0">
        <div className="">
          <div className="" data-aos="fade-up">
          <ImageGallery className="transition" items={images} />
          </div>
          <DetailsOfListing
          Title="APARTMENT FOR SALE IN SOUTH LIVING, DUBAI SOUTH (DUBAI WORLD CENTRAL)"
          Title2="Premier | 3BR South Living | by Dubai South"
          Price="1,938,496"
          Location="Dubai"
          Category="Apartment"
          Beds="3"
          Baths="4"
          Area="1,669 sqft / 155 sqm"
          FeaturesAndAmenities={<>
            <FeaturesAndAmenities Title="Study" />
            <FeaturesAndAmenities Title="Central A/C" />
            <FeaturesAndAmenities Title="Balcony" />
            <FeaturesAndAmenities Title="Private Pool" />
            <FeaturesAndAmenities Title="Private Spa" />
            <FeaturesAndAmenities Title="Shared Pool" />
            <FeaturesAndAmenities Title="Shared Spa" />
            <FeaturesAndAmenities Title="Security" />
            <FeaturesAndAmenities Title="Built in Wardrobes" />
            <FeaturesAndAmenities Title="Shared Gym" />
            <FeaturesAndAmenities Title="Lobby in Building" />
            <FeaturesAndAmenities Title="Children's Pool" />
            <FeaturesAndAmenities Title="Children's Play Area" />
            <FeaturesAndAmenities Title="Barbecue Area" />  
            </>}
          Description={<>
       Apartment for sale in South Living, Dubai South (Dubai World Central)
       <div className="py-2" />
       Discover South Living by Dubai South, the latest premium development that redefines modern living. This stunning 12-storey façade offers meticulously designed studios, 1, 2, and 3-bedroom apartments, where luxury meets convenience in the vibrant heart of Dubai South.
       <div className="py-2" />
       Enjoy a prime location with effortless access to Expo Road and an array of amenities just steps away. Grocery stores, dining options, and recreational facilities are all within reach, while a nearby community park provides a serene escape. Seamless connectivity to major landmarks across Dubai ensures you’re always at the center of it all.
       <div className="py-2" />
       Step into a grand entrance lobby featuring a striking waterfall wall that sets the tone for elegance. Each apartment showcases sleek finishes and spacious layouts, crafted for your utmost comfort. Indulge in a wealth of amenities designed to enhance your lifestyle, including a state-of-the-art gym, refreshing pool, and dedicated play areas for children.
       <div className="py-2" />
       For outdoor gatherings, the BBQ area and gazebo seating offer the perfect backdrop for leisurely evenings. Additional features like a library and multipurpose hall ensure a holistic living experience that caters to all your needs.
       <div className="py-2" />
       Key Highlights: <br />
       Spacious studios and 1, 2, & 3-bedroom apartments <br />
       Prime location in Dubai South Residential District <br />
       Double-height entrance lobby with a feature waterfall wall <br />
       Modern design blending community living with comfort <br />
       Eco-conscious practices and sustainable environment <br />
       Extensive amenities including gym, pool, sauna, and multipurpose hall
       <div className="py-2" />
       Elevate your lifestyle at South Living—where every detail is designed with you in mind.
          </>}
          ListingDetailsSection={<>
          <ListingDetailsSection Title1="Property Type" Title2="Apartment" />          
          <ListingDetailsSection Title1="Property Size" Title2="1,669 sqft / 155 sqm" />          
          <ListingDetailsSection Title1="Bedrooms" Title2="3" />          
          <ListingDetailsSection Title1="Bathrooms" Title2="4" />     
          </>}
          />
      </div>
        </div>
        <div className="col-11 col-md-3">
          <div className="border-white border-1 border p-4 mb-4 sticky-top">
          <InquiryOfListing />
          <ShareSocialMediaIcons
          url_Email="https://800homes.eacademe.org/apartment-for-sale-dubai-dubai-south-dubai-world-central-south-living-12484009"
          url_Whatsapp="https://800homes.eacademe.org/apartment-for-sale-dubai-dubai-south-dubai-world-central-south-living-12484009"
          url_Facebook="https://800homes.eacademe.org/apartment-for-sale-dubai-dubai-south-dubai-world-central-south-living-12484009"
          url_Linkedin="https://800homes.eacademe.org/apartment-for-sale-dubai-dubai-south-dubai-world-central-south-living-12484009"
          />
          </div>
        </div>        
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default Listing11 