import React, { Component } from "react";
import Slider from "react-slick";
import {Link} from 'react-router-dom';

const HomeBanner = () => {
  return (
    <>
    {/* Space */}
    <div className="py-5 pt-5 mt-3 my-md-4" />

      <div id="home-banner" className="col-10 mx-auto ps-md-3">
        <div className="fw-500 text-white fs-home-banner text-uppercase lh-sm" data-aos="fade-up">
        Turn the Key to 
        <br className="d-none-d-md-block" />
        Your Dubai Dream
        </div>
        <div className="text-white fs-sub-home-banner fw-400 pt-2 pt-md-3" data-aos="fade-up">
        Because Every Great Story <br className="d-block d-md-none" /> Begins at Home
        </div>
      </div>
    </>
  )
}

export default HomeBanner