import React, { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Img1 from '../../assets/images/listing/listing18/1.webp';
import Img2 from '../../assets/images/listing/listing18/2.webp';
import Img3 from '../../assets/images/listing/listing18/3.webp';
import Img4 from '../../assets/images/listing/listing18/4.webp';
import Img5 from '../../assets/images/listing/listing18/5.webp';
import Img6 from '../../assets/images/listing/listing18/6.webp';
import Img7 from '../../assets/images/listing/listing18/7.webp';
import Img8 from '../../assets/images/listing/listing18/8.webp';
import Img9 from '../../assets/images/listing/listing18/9.webp';
import Img10 from '../../assets/images/listing/listing18/10.webp';

import Header from "../../components/layout/Header";

import Footer from "../../components/layout/Footer";
import DetailsOfListing from "../../components/listing/individual/DetailsOfListing";
import InquiryOfListing from "../../components/listing/individual/InquiryOfListing";
import ShareSocialMediaIcons from "../../components/listing/individual/ShareSocialMediaIcons";
import { Link } from "react-router-dom";
import FeaturesAndAmenities from "../../components/listing/individual/FeaturesAndAmenities";
import ListingDetailsSection from "../../components/listing/individual/ListingDetailsSection";

const Listing18 = (props) => {
  
  const images = [
    {
      original: Img1,
      thumbnail: Img1,
    },
    {
      original: Img2,
      thumbnail: Img2,      
    },
    {
      original: Img3,
      thumbnail: Img3,      
    },
    {
      original: Img4,
      thumbnail: Img4,      
    },
    {
      original: Img5,
      thumbnail: Img5,      
    },
    {
      original: Img6,
      thumbnail: Img6,      
    },
    {
      original: Img7,
      thumbnail: Img7,      
    },
    {
      original: Img8,
      thumbnail: Img8,      
    },
    {
      original: Img9,
      thumbnail: Img9,      
    },
    {
      original: Img10,
      thumbnail: Img10,      
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Header/>
    <section id="website-responsive" className="container-fluid py-5 border-top border-1 border-white">
      <div className="row justify-content-evenly">
        <div className="col-11 col-md-7 px-0">
        <div className="">
          <div className="" data-aos="fade-up">
          <ImageGallery className="transition" items={images} />
          </div>
          <DetailsOfListing
          Title="TOWNHOUSE FOR SALE IN CAMELIA, DAMAC HILLS 2"
          Title2="Elegant Layout | 3BR TH | Camelia - Damac Hills"
          Price="1,340,000"
          Location="Dubai"
          Category="Townhouse"
          Beds="3"
          Baths="4"
          Area="1,208 sqft / 112 sqm"
          FeaturesAndAmenities={<>
            <FeaturesAndAmenities Title="Central A/C" />
            <FeaturesAndAmenities Title="Balcony" />
            <FeaturesAndAmenities Title="Private Garden" />
            <FeaturesAndAmenities Title="Shared Pool" />
            <FeaturesAndAmenities Title="Security" />
            <FeaturesAndAmenities Title="Built in Wardrobes" />
            <FeaturesAndAmenities Title="Shared Gym" />
            <FeaturesAndAmenities Title="Children's Pool" />
            <FeaturesAndAmenities Title="Children's Play Area" />
            <FeaturesAndAmenities Title="Barbecue Area" />   
            </>}
          Description={<>
          Introducing the latest luxury offering from Damac Properties: stunning 3-bedroom townhouses designed for those who seek elegance and comfort. Camelia Villas is a sanctuary that harmoniously blends superior living with an array of epic amenities, ensuring you and your loved ones experience life to the fullest.
          <div className="py-2" />
          These thoughtfully crafted townhouses feature timeless designs and creative decor, providing a perfect retreat from the city’s hustle and bustle while remaining conveniently close to major landmarks. Enjoy a wealth of space both inside and out, ideal for families who prioritize comfort and quality.
          <div className="py-2" />
          Experience an extraordinary lifestyle surrounded by exceptional sports facilities and rejuvenating water attractions. Whether you’re unwinding in your private garden or engaging in recreational activities, every aspect of Camelia Villas promotes relaxation and joy.
          <div className="py-2" />
          The elegant layout showcases state-of-the-art finishes, combining luxury with everyday convenience. Elevate your living experience and create unforgettable memories in a home that feels like a holiday destination.
          <div className="py-2" />
        Key Highlights: <br />
        Luxurious 3-bedroom townhouses <br />
        Premium design with private gardens <br />
        Abundant sports amenities and water features <br />
        Serene location amidst nature <br />
        Close proximity to world-class lifestyle destinations
        <div className="py-2" />
        Make the remarkable choice for your family today!

          </>}
          ListingDetailsSection={<>
          <ListingDetailsSection Title1="Property Type" Title2="Townhouse" />          
          <ListingDetailsSection Title1="Property Size" Title2="1,208 sqft / 112 sqm" />          
          <ListingDetailsSection Title1="Bedrooms" Title2="3" />          
          <ListingDetailsSection Title1="Bathrooms" Title2="4" />     
          </>}
          />
      </div>
        </div>
        <div className="col-11 col-md-3">
          <div className="border-white border-1 border p-4 mb-4 sticky-top">
          <InquiryOfListing />
          <ShareSocialMediaIcons
          url_Email="https://800homes.eacademe.org/townhouse-for-sale-dubai-damac-hills-2-camelia"
          url_Whatsapp="https://800homes.eacademe.org/townhouse-for-sale-dubai-damac-hills-2-camelia"
          url_Facebook="https://800homes.eacademe.org/townhouse-for-sale-dubai-damac-hills-2-camelia"
          url_Linkedin="https://800homes.eacademe.org/townhouse-for-sale-dubai-damac-hills-2-camelia"
          />
          </div>
        </div>        
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default Listing18 