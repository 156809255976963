import React from 'react';
import { usePropertiesContext } from '../../context/PropertiesContext';

import SingleListingSlider from './SingleListingSlider';
import Slider from 'react-slick';

const HomeSliderListing = () => {
    const { properties, loading } = usePropertiesContext();
    var settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        pauseOnHover: false,
        responsive: [
        {
            breakpoint: 991,
            settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
            infinite: true,
            dots: false
            }
        },
        {
            breakpoint: 768,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            infinite: true,
            dots: false
            }
        }
        ]
    };
    return (
        <>  
            {loading ? <div className="text-center fs-ss-18 lh-1-7em fw-300 col-md-8 mx-auto text-white py-5" data-aos="fade-up">
                Loading...
            </div> :
            <>
            {properties.length === 0 ? <div className=" text-white fs-ss-18 lh-1-7em fw-300 col-md-8 mx-auto text-center py-5" data-aos="fade-up">
                    No Property Found !
                </div> :
                <Slider className="" {...settings} >
                    {properties.map((property, index) => (
                        // shown only 7 properties
                        index < 7 && (
                        <SingleListingSlider
                            key={index}
                            item={property}
                            images={property.images}
                        />
                        )
                    ))}
                </Slider>   
            }
            </>
            }
        </>
    );
}

export default HomeSliderListing;
