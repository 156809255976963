import React from 'react'
import SingleListing from './SingleListing'

const AllPerperties = ({data}) => {
    return (
        <>
            {data.length === 0 ? <div className=" text-white fs-ss-18 lh-1-7em fw-300 col-md-8 mx-auto text-center py-5" data-aos="fade-up">
                No Property Found For This Category !
            </div> :
            <>
                {data.map((item, index) => {
                    return (
                        <SingleListing
                            key={index}
                            item={item}
                            images={item.images}
                        />
                    )
                }
                )}      
            </>
            }
        </>
    )
}

export default AllPerperties
