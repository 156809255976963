import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import HomeBanner from '../components/home/HomeBanner';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import { Link } from 'react-router-dom';
import Img1 from '../assets/images/why-800-homes/Img1.webp';
import Img2 from '../assets/images/why-800-homes/Img2.webp';
import { CheckLg } from 'react-bootstrap-icons';
import Icon1 from '../assets/images/why-800-homes/villa.png';
import Icon2 from '../assets/images/why-800-homes/residential.png';
import Icon3 from '../assets/images/why-800-homes/penthouse.png';
import Icon4 from '../assets/images/why-800-homes/house.png';
import BannerImg from '../assets/images/banners/BannerWhy800Homes.webp';


const Why800Homes = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
            <>
        <Helmet>
        <meta charSet="utf-8" />
        <title> Why Choose 800 Homes? | Trusted Real Estate Brokerage in Dubai </title>
        <meta name="description" content="Find out why 800 Homes is the preferred real estate brokerage in Dubai. With our expert team, premium listings, and deep market knowledge, we help you make the right property investment." />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Why Choose 800 Homes? | Trusted Real Estate Brokerage in Dubai" />
        <meta property="og:description" content="Find out why 800 Homes is the preferred real estate brokerage in Dubai. With our expert team, premium listings, and deep market knowledge, we help you make the right property investment." />
        <meta property="og:url" content="https://800homes.ae/" />
        <meta property="og:site_name" content="800homes" />
        <meta property="og:image" content="https://800homes.ae/og.webp" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://800homes.ae/og.webp" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Find out why 800 Homes is the preferred real estate brokerage in Dubai. With our expert team, premium listings, and deep market knowledge, we help you make the right property investment." />
        <meta name="twitter:title" content="Why Choose 800 Homes? | Trusted Real Estate Brokerage in Dubai" />
        <meta name="twitter:image" content="https://800homes.ae/og.webp"/>
        <link rel="canonical" href="https://800homes.ae/why-800-homes"/>
        </Helmet>

    <section id='website-responsive' className="container-fluid px-0">
    <section id='website-responsive' className="container-fluid bg-common-banner bg-home vh-100 px-0" style={{backgroundImage:`url(${BannerImg})`}}>
    <Header/>
    <div className="py-md-5 pt-4 mt-4 my-md-4" />
    <div id='common-banner' className="col-10 mx-auto ps-md-3">
      <div className="fw-500 text-white fs-home-banner text-uppercase lh-sm" data-aos="fade-up">
        Find Your Dream Home 
        <br className="d-none-d-md-block" />
        in Dubai with Us 
      </div>
      <div className="text-white fs-sub-home-banner fw-400 pt-2 pt-md-3" data-aos="fade-up">
        Why 800 Homes
        </div>
        </div>    
    </section>

    <section className="py-md-5 py-4" />

    <section className="container-fluid">
      <div className="row justify-content-evenly gy-4 text-md-center">
        <div className="col-11 col-md-12">
          <div className=" text-white fs-ss-18 lh-1-7em fw-300 col-md-8 mx-auto" data-aos="fade-up">
          Discover a new level of real estate service in Dubai. Choosing 800 Homes means working with a team that knows the Dubai real estate market inside and out as a leading real estate brokerage company in Dubai. We break through traditional brokerage services by offering a personalized approach that meets your preferences and goals.  
          <div className="py-2" />
          Our commitment to excellence reflects our deep market insights and ability to match clients with properties that align with their lifestyle and investment targets. You get access to premium listings and a team that values transparency, integrity, and exceptional service.  
          </div>
          <div className="pt-4 text-white fs-ss-18 fw-400" data-aos="fade-up">
            <Link to="/contact-us" className='text-decoration-none px-4 py-ss-2 text-white bg-ss-primary bg-hover-ss-primary rounded-5'>
            Inquire Now
            </Link>
          </div>
        </div>
      </div>
    </section>

     <section className="py-md-5 py-4" />
    {/* Mobile Space */}
    <section className="pt-4 pt-md-0" />

    <section className="container-fluid">
      <div className="row justify-content-center justify-content-md-end gy-4">
        <div className="col-11 col-md-5 align-self-center">
        
        <div className="col-12 col-md-10 mx-auto">

        <div className="text-uppercase fw-500 pb-2 pb-md-4">
          <div className="fs-ss-30 text-white text-shadow-parent" data-aos="fade-up">
          Expertise That Defines Us
          </div>
        </div>

          <div className="pb-md-4 text-white fs-ss-18 fw-300 col-md-10">

            <div className="pb-4 lh-1-7em" data-aos="fade-up">
            Our strength lies in our strong expertise in the Dubai real estate market. Our experienced team provides reliable and practical solutions, facilitating a hassle-free and successful real estate process.   
            </div>

            <div className="pb-4" data-aos="fade-up">
            <div className="text-ss-primary fs-ss-22 fw-600">
            Client-Centric Services  
            </div>
            <div className="fw-300 lh-1-7em">
            We know that every client has exclusive needs. 800 Homes offers tailored solutions to meet your specific requirements. From buying a new home to selling property or investing in Dubai real estate, our bespoke service offers the support you need to achieve your goals.  
            </div>
            </div>

          </div>
          </div>
        </div>
        <div className="col-11 col-md-6 px-0" >
          <img src={Img1} className='w-100' data-aos="fade-up" alt="" />
        </div>
      </div>
    </section>

      <section className="py-md-5 py-4" />
    {/* Mobile Space */}
    <section className="pt-4 pt-md-0" />

      <section className="container-fluid">
      <div className="row justify-content-evenly gy-4 text-md-center">
        <div className="col-11 col-md-12">
          <div className="fs-ss-30 fw-600 text-ss-primary pb-3" data-aos="fade-up">
          Finest Properties to Match Every Lifestyle  
          </div>
          <div className=" text-white fs-ss-18 lh-1-7em fw-300 col-md-8 mx-auto" data-aos="fade-up">
          800 Homes has a curated property portfolio that reflects every lifestyle and preference. From luxurious villas and stylish modern apartments to premium commercial sites, our selection offers something for everyone.  
          </div>
        </div>
      </div>
    </section>

    <section className="py-md-5 py-4 my-md-2" />
    {/* Mobile Space */}
    <section className="pt-4 pt-md-0" />

    <section className="container-fluid">
      <div className="row justify-content-evenly align-items-center gy-5">
      <div className="col-11 col-md-5 px-0 d-none d-md-block" >
          <img src={Img2} className='w-100' data-aos="fade-up" alt="" />
        </div>
        <div className="col-11 col-md-5">
          <div className="fs-ss-30 fw-600 text-white pb-4" data-aos="fade-up">
          Expert Assistance at Every Step  
          </div>

          <div className="pb-md-4 text-white fs-ss-18 fw-300 col-md-10">

            <div className="pb-4" data-aos="fade-up">
            <div className="d-flex pb-2">
                <div className="">
                <CheckLg className='fs-ss-30 text-ss-primary me-3'/>
                </div>
                <div className="lh-1-7em">
                Get expert help to handle the buying or selling of a property process.  
                </div>
              </div>
              <div className="d-flex pb-2">
                <div className="">
                <CheckLg className='fs-ss-30 text-ss-primary me-3'/>
                </div>
                <div className="lh-1-7em">
                Receive strategic advice to help you make smart investments in the Dubai real estate market.  
                </div>
              </div>
              <div className="d-flex">
                <div className="">
                <CheckLg className='fs-ss-30 text-ss-primary me-3'/>
                </div>
                <div className="lh-1-7em">
                Access detailed analysis of market trends and opportunities to guide your decisions.  
                </div>
              </div>
            </div>
          </div>
          <div className="text-white fs-ss-18 fw-400" data-aos="fade-up">
            <Link to="/contact-us" className='text-decoration-none px-4 py-ss-2 text-white bg-ss-primary bg-hover-ss-primary rounded-5'>
            Get in Touch
            </Link>
          </div>
        </div>
        <div className="col-11 col-md-5 px-0 d-block d-md-none" >
          <img src={Img2} className='w-100' data-aos="fade-up" alt="" />
        </div>
      </div>
    </section>

    <section className="py-md-5 py-4 my-md-2" />
    {/* Mobile Space */}
    <section className="pt-4 pt-md-0" />

    <section className="container-fluid">
      <div className="row justify-content-center align-items-center gy-4">
      <div className="col-11 col-md-4 px-0 pb-4 pb-md-0" >
      <div className="col-11 col-md-6 mx-auto">
      <div className="fs-ss-48 fw-600 text-white pb-md-4" data-aos="fade-up">
            Premium <br className='d-none d-md-block' /> Property <br className='d-none d-md-block' /> for Buyers
          </div>
      <div className="text-white fs-ss-18 fw-400 d-none d-md-block" data-aos="fade-up">
            <Link to="/properties-for-sale" className='text-decoration-none px-4 py-ss-2 text-white bg-ss-primary bg-hover-ss-primary rounded-5'>
            Buy Property
            </Link>
          </div>
          </div>
          </div>
          <div className="col-11 col-md-6">   
          <div className="row justify-content-center gy-4">

          <div className="col-11 col-md-5 border-ss-primary-hover text-center p-4 me-md-4" data-aos="fade-up">
              <img src={Icon1} className='w-icons mb-4' alt="" />
              <div className="pb-2 fs-ss-18 fw-500 text-white">
              Luxury Villas 
              </div>
              <div className="fs-ss-16 fw-300 text-grey">
              Exquisite Homes with Premium Features  
              </div>
            </div>

            <div className="col-11 col-md-5 border-ss-primary-hover text-center p-4" data-aos="fade-up">
              <img src={Icon2} className='w-icons mb-4' alt="" />
              <div className="pb-2 fs-ss-18 fw-500 text-white">
              Stylish Apartments   
              </div>
              <div className="fs-ss-16 fw-300 text-grey">
              Elegant Residences with Modern Amenities 
              </div>
            </div>

            <div className="col-11 col-md-5 border-ss-primary-hover text-center p-4 me-md-4" data-aos="fade-up">
              <img src={Icon3} className='w-icons mb-4' alt="" />
              <div className="pb-2 fs-ss-18 fw-500 text-white">
              Exclusive Penthouses   
              </div>
              <div className="fs-ss-16 fw-300 text-grey">
              Prestigious Living Spaces with High-End Finishes 
              </div>
            </div>

            <div className="col-11 col-md-5 border-ss-primary-hover text-center p-4" data-aos="fade-up">
              <img src={Icon4} className='w-icons mb-4' alt="" />
              <div className="pb-2 fs-ss-18 fw-500 text-white">
              Spacious Family Homes   
              </div>
              <div className="fs-ss-16 fw-300 text-grey">
              Comfortable and Generous Living Spaces 
              </div>
            </div>

          </div>
        </div>
      </div>
      </section>

    <section className="py-md-5 py-4" />
    {/* Mobile Space */}
    <section className="pt-4 pt-md-0" />
    
    <section className="container-fluid">
      <div className="row justify-content-evenly gy-4 text-md-center">
        <div className="col-11 col-md-12">
          <div className="fs-ss-30 fw-600 text-ss-primary pb-3" data-aos="fade-up">
          Your Success is Our Priority  
          </div>
          <div className=" text-white fs-ss-18 lh-1-7em fw-300 col-md-9 mx-auto" data-aos="fade-up">
          When you choose 800 Homes, you choose a real estate team that puts your success first. We take the time to understand your goals and work diligently to meet your expectations. No matter if you're buying, selling, or investing in Dubai real estate, our transparent and focused approach delivers you the best support and advice throughout your journey.  
          </div>
        </div>
      </div>
    </section>

     <section className="py-md-5 py-4" />

    <Footer/>
    </section>
    </>
  );
}

export default Why800Homes;
