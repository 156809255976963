import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
 
const FAQsSection = (props) => {
  return (
    <>
                      <Accordion.Item eventKey={props.EvenyKey} className='pt-2'>
                          <Accordion.Header className=''>
                              <span className='faqs-heading text-white py-2 fw-300 fs-ss-22'>
                                {props.Title}
                              </span>
                          </Accordion.Header>
                          <Accordion.Body className='text-start text-white col-11 fs-ss-16 fw-300 lh-1-7em pb-4'>
                              <span className='faqs-ans'>
                                {props.Para}
                              </span>
                          </Accordion.Body>
                      </Accordion.Item>
                    
      </>
  );
}

export default FAQsSection;
