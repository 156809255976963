import React, {useState, useRef} from "react"
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from 'emailjs-com'

const InquiryOfListing = ({propertyName}) =>{
  const [verified, setVarified] = useState(false)
  function onChange(value) {
    console.log("Captcha value:", value);
    setVarified(true);
  }
  const refform = useRef();
  const sendForm = (e) =>{
    e.preventDefault();
    emailjs.sendForm('service_52cv4u2','800homes',e.target,'3cIb1sgzlfNqwBYS8').then(res=>{
      console.log(res)
      window.location.href="/thank-you"
  }).catch(err=>{
    console.log(err)
  });
  e.target.reset()
};

    return (
    <>
    <section className="container-fluid bg-grey-2 rounded-0 px-0">
        <div className="row justify-content-center align-items-center">
            <div className="col-12">
                <div className='fs-ss-22 fw-500 lh-sm text-white pb-4 text-center'>
                  Inquiry - 800 Homes
                </div>
                <div className="">
                <form ref={refform} onSubmit={sendForm} id="contactForm" method="POST" action="#" className="">
      <div className="form-group mb-3">
        <input type="text" className="form-control text-grey fs-ss-16 fw-300" id="name" name="name" placeholder="Name *" required/>
      </div>
      <div className="form-group mb-3">
        <input type="email" className="form-control text-grey fs-ss-16 fw-300" id="email" name="email" placeholder="Email *" required/>
      </div>
      <div className="form-group mb-3">
        <input type="number" className="form-control text-grey fs-ss-16 fw-300" id="phone_number" name="phone_number" placeholder="Phone Number *" required/>
      </div>
      <div className="form-group mb-4">
        <textarea className="form-control text-grey fs-ss-16 fw-300" value={`I'm interested in [${propertyName}]`} id="message" name="message" rows="4" placeholder="Message"></textarea>
      </div>
      <div className="">
          <button className='mt-2 text-center text-white fw-300 bg-ss-primary bg-hover-ss-primary rounded-0 border-0 text-ss-secondary fs-ss-16 px-3 py-ss-2 w-100'>
            Submit
          </button>
      </div>
    </form>
    <div className="d-flex">
            <div className="w-50 pe-1">
            <a href="tel:+971585746637" className='d-block text-decoration-none mt-2 text-center text-white fw-300 border-ss-primary bg-hover-ss-primary rounded-0 fs-ss-16 px-3 py-ss-2 w-100'>
            Call
          </a>
            </div>
            <div className="w-50 ps-1">
            <a target="_blank" href="https://api.whatsapp.com/send/?phone=971585746637&text&type=phone_number&app_absent=0" className='d-block text-decoration-none mt-2 text-center text-white fw-300 border-ss-primary bg-hover-ss-primary rounded-0 fs-ss-16 px-3 py-ss-2 w-100'>
            WhatsApp
          </a>
            </div>
          </div>
    </div>
                </div>
        </div>
    </section>
    </>
    )
}

export default InquiryOfListing;