import React, { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Img1 from '../../assets/images/listing/listing13/1.webp';
import Img2 from '../../assets/images/listing/listing13/2.webp';
import Img3 from '../../assets/images/listing/listing13/3.webp';
import Img4 from '../../assets/images/listing/listing13/4.webp';
import Img5 from '../../assets/images/listing/listing13/5.webp';
import Img6 from '../../assets/images/listing/listing13/6.webp';
import Img7 from '../../assets/images/listing/listing13/7.webp';
import Img8 from '../../assets/images/listing/listing13/8.webp';
import Img9 from '../../assets/images/listing/listing13/9.webp';
import Img10 from '../../assets/images/listing/listing13/10.webp';
import Img11 from '../../assets/images/listing/listing13/11.webp';
import Img12 from '../../assets/images/listing/listing13/12.webp';
import Img13 from '../../assets/images/listing/listing13/13.webp';
import Img14 from '../../assets/images/listing/listing13/14.webp';
import Img15 from '../../assets/images/listing/listing13/15.webp';

import Header from "../../components/layout/Header";

import Footer from "../../components/layout/Footer";
import DetailsOfListing from "../../components/listing/individual/DetailsOfListing";
import InquiryOfListing from "../../components/listing/individual/InquiryOfListing";
import ShareSocialMediaIcons from "../../components/listing/individual/ShareSocialMediaIcons";
import { Link } from "react-router-dom";
import FeaturesAndAmenities from "../../components/listing/individual/FeaturesAndAmenities";
import ListingDetailsSection from "../../components/listing/individual/ListingDetailsSection";

const Listing13 = (props) => {
  
  const images = [
    {
      original: Img1,
      thumbnail: Img1,
    },
    {
      original: Img2,
      thumbnail: Img2,      
    },
    {
      original: Img3,
      thumbnail: Img3,      
    },
    {
      original: Img4,
      thumbnail: Img4,      
    },
    {
      original: Img5,
      thumbnail: Img5,      
    },
    {
      original: Img6,
      thumbnail: Img6,      
    },
    {
      original: Img7,
      thumbnail: Img7,      
    },
    {
      original: Img8,
      thumbnail: Img8,      
    },
    {
      original: Img9,
      thumbnail: Img9,      
    },
    {
      original: Img10,
      thumbnail: Img10,      
    },
    {
      original: Img11,
      thumbnail: Img11,      
    },
    {
      original: Img12,
      thumbnail: Img12,      
    },
    {
      original: Img13,
      thumbnail: Img13,      
    },
    {
      original: Img14,
      thumbnail: Img14,      
    },
    {
      original: Img15,
      thumbnail: Img15,      
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Header/>
    <section id="website-responsive" className="container-fluid py-5 border-top border-1 border-white">
      <div className="row justify-content-evenly">
        <div className="col-11 col-md-7 px-0">
        <div className="">
          <div className="" data-aos="fade-up">
          <ImageGallery className="transition" items={images} />
          </div>
          <DetailsOfListing
          Title="VILLA FOR SALE IN SOUTH BAY 5, SOUTH BAY"
          Title2="6BR Mansion | South Bay - Dubai South"
          Price="17,000,000"
          Location="Dubai"
          Category="Villa"
          Beds="6 + Maid"
          Baths="7"
          Area="11,220 sqft / 1,042 sqm"
          FeaturesAndAmenities={<>
          <FeaturesAndAmenities Title="Maids Room" />  
            <FeaturesAndAmenities Title="Central A/C" />
            <FeaturesAndAmenities Title="Balcony" />
            <FeaturesAndAmenities Title="Private Garden" />
            <FeaturesAndAmenities Title="Private Gym" />
            <FeaturesAndAmenities Title="Shared Pool" />
            <FeaturesAndAmenities Title="Shared Spa" />
            <FeaturesAndAmenities Title="Security" />
            <FeaturesAndAmenities Title="Built in Wardrobes" />
            <FeaturesAndAmenities Title="Shared Gym" />
            <FeaturesAndAmenities Title="Children's Play Area" />
            <FeaturesAndAmenities Title="Barbecue Area" />  

            </>}
          Description={<>
          Villa for sale in South Bay 5, South Bay
         <div className="py-2" /> 
          Experience luxury living redefined at South Bay, an exceptional development by Dubai South Developer. This exclusive gated community features stunning 3 to 7-bedroom villas, townhouses, and grand mansions, all centered around a breathtaking 3-kilometer crystal lagoon.
          <div className="py-2" /> 
          Each residence is thoughtfully designed with modern façades, offering spectacular lagoon views. The community is not just about luxurious homes; it’s a lifestyle embracing expansiveness and endless possibilities. The crystal lagoon serves as the heart of South Bay, providing a serene setting for relaxation, sunbathing, or engaging in water sports amid beautifully landscaped surroundings.
          <div className="py-2" /> 
          South Bay is developed in five phases, offering over 1,200 premium units that foster a strong sense of community. Whether you seek gourmet dining, family time, or personal wellness, this vibrant environment caters to every need.
          <div className="py-2" /> 
          Strategically located near the world’s largest airport, South Bay ensures excellent connectivity to major highways and key landmarks like Expo Road, making it ideal for residents and investors alike.
          <div className="py-2" /> 
          This smart, sustainable community promotes a greener lifestyle with energy-efficient designs and modern conveniences. Residents enjoy a wealth of world-class amenities, including a health and wellness complex, beach clubs, shopping mall, and lush parks.
          <div className="py-2" /> 
          Key Highlights: <br />
          Diverse housing options: 3-7 bedroom units <br />
          Stunning lagoon views and extensive facilities <br />
          Eco-conscious design for a healthier living environment
          <div className="py-2" /> 
          Discover the unparalleled lifestyle at South Bay, where luxury meets community!
          </>}
          ListingDetailsSection={<>
          <ListingDetailsSection Title1="Property Type" Title2="Villa" />          
          <ListingDetailsSection Title1="Property Size" Title2="11,220 sqft / 1,042 sqm" />          
          <ListingDetailsSection Title1="Bedrooms" Title2="6 + Maid" />          
          <ListingDetailsSection Title1="Bathrooms" Title2="7" />     
          </>}
          />
      </div>
        </div>
        <div className="col-11 col-md-3">
          <div className="border-white border-1 border p-4 mb-4 sticky-top">
          <InquiryOfListing />
          <ShareSocialMediaIcons
          url_Email="https://800homes.eacademe.org/villa-for-sale-dubai-dubai-south-dubai-world-central-south-bay-south-bay-5-12484195"
          url_Whatsapp="https://800homes.eacademe.org/villa-for-sale-dubai-dubai-south-dubai-world-central-south-bay-south-bay-5-12484195"
          url_Facebook="https://800homes.eacademe.org/villa-for-sale-dubai-dubai-south-dubai-world-central-south-bay-south-bay-5-12484195"
          url_Linkedin="https://800homes.eacademe.org/villa-for-sale-dubai-dubai-south-dubai-world-central-south-bay-south-bay-5-12484195"
          />
          </div>
        </div>        
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default Listing13 