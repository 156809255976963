import React from "react";
import { EnvelopeFill, GeoAlt, GeoAltFill, TelephoneFill, Whatsapp } from "react-bootstrap-icons";
import {Link} from 'react-router-dom';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Beds from '../../assets/images/listing/doublebed.png';
import Shower from '../../assets/images/listing/shower.png';
import SqFt from '../../assets/images/listing/ruller.png';

const SingleListingSlider = ({item, images}) => {

    return (
        <>
            <div className="slider-item">
                <div id="listing" className="text-grey border-1 border gy-4 border-white px-0 mx-4 mx-md-3 flex-grow-1 d-flex flex-column" data-aos="fade-up">
                {/* Image */}
                    <ImageGallery items={
                        images.map((image) => {
                            return {
                                original: image.file,
                            }}
                        )
                    } />
                    <div className="ps-3 pt-3 pb-1 flex-grow-1">
                        <Link to={`/property/`+item.url} className="title-color text-grey align-text-line text-decoration-none fs-ss-22 fw-400">
                            {item.name}
                        </Link>
                        <div className="fs-ss-16 fw-400 pt-4 pb-1">
                            <GeoAlt className="mb-1"/> {item.location.city}
                        </div>
                        <div className="fs-ss-16 fw-300">
                            {item.type.name}
                        </div>

                        <div className="d-flex align-items-center fs-ss-14 fw-300 mt-2">
                            <div className="me-3">
                                <img src={Beds} className="listing-icons mb-2" alt="" />
                                <span className=""> Beds: </span>           
                                <span className=""> {item.bedrooms} </span> 
                            </div>
                            
                            <div className="me-3">
                                <img src={Shower} className="listing-icons mb-2" alt="" />
                                <span className=""> Baths: </span>           
                                <span className=""> {item.bathrooms} </span>                    
                            </div>
                            
                            <div className="">
                                <img src={SqFt} className="listing-icons me-1 mb-2" alt="" />
                                <span className=""> {Math.round(item.size)} sqft</span> 
                                <span className="ms-2"> / {Math.round(item.size * 0.092903)} sqm </span>
                            </div>          
                        </div>
                    </div>
                    <div className="text-uppercase fw-300 fs-ss-22 border-top border-1 border-white py-1 mx-3 mt-3">
                        AED <span className=""> {item.price?.value?.toLocaleString()} </span>
                    </div>

                    {/* Contact Us */}      
                    <div className="row justify-content-center fs-ss-18 text-center border-white border-1 border-top gx-0 align-items-center">
                        <a href="tel:+971585746637" className="col-4 text-decoration-none text-ss-primary text-hover-ss-primary" > <TelephoneFill/> </a>
                        <a href="mailto:inquiry@800homes.ae" className="col-4 border-start border-end border-1 border-white py-1 text-decoration-none text-ss-primary text-hover-ss-primary" > <EnvelopeFill/> </a>
                        <a target="_blank" href="https://api.whatsapp.com/send/?phone=971585746637&text&type=phone_number&app_absent=0" className="col-4 text-decoration-none text-ss-primary text-hover-ss-primary" > <Whatsapp/> </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SingleListingSlider