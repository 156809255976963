import React from 'react';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import InquireNowForm from '../InquireNowForm';

const Header = () => {
  return (
    <>
    <section className="">
    <div className='d-none d-md-block' data-aos="fade">
      <DesktopHeader/>
    </div>
    <div className="d-block d-md-none" data-aos="fade">
      <MobileHeader/>
    </div>
    <InquireNowForm/>
    </section>
    </>
  );
}

export default Header;
