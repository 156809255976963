import React, { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Img1 from '../../assets/images/listing/listing3/1.webp';
import Img2 from '../../assets/images/listing/listing3/2.webp';
import Img3 from '../../assets/images/listing/listing3/3.webp';
import Img4 from '../../assets/images/listing/listing3/4.webp';
import Img5 from '../../assets/images/listing/listing3/5.webp';
import Img6 from '../../assets/images/listing/listing3/6.webp';
import Img7 from '../../assets/images/listing/listing3/7.webp';
import Img8 from '../../assets/images/listing/listing3/8.webp';
import Img9 from '../../assets/images/listing/listing3/9.webp';
import Img10 from '../../assets/images/listing/listing3/10.webp';
import Img11 from '../../assets/images/listing/listing3/11.webp';
import Img12 from '../../assets/images/listing/listing3/12.webp';
import Img13 from '../../assets/images/listing/listing3/13.webp';
import Img14 from '../../assets/images/listing/listing3/14.webp';
import Img15 from '../../assets/images/listing/listing3/15.webp';
import Img16 from '../../assets/images/listing/listing3/16.webp';
import Header from "../../components/layout/Header";

import Footer from "../../components/layout/Footer";
import DetailsOfListing from "../../components/listing/individual/DetailsOfListing";
import InquiryOfListing from "../../components/listing/individual/InquiryOfListing";
import ShareSocialMediaIcons from "../../components/listing/individual/ShareSocialMediaIcons";
import { Link } from "react-router-dom";
import ListingDetailsSection from "../../components/listing/individual/ListingDetailsSection";
import FeaturesAndAmenities from "../../components/listing/individual/FeaturesAndAmenities";

const Listing3 = (props) => {
  
  const images = [
    {
      original: Img1,
      thumbnail: Img1,
    },
    {
      original: Img2,
      thumbnail: Img2,      
    },
    {
      original: Img3,
      thumbnail: Img3,      
    },
    {
      original: Img4,
      thumbnail: Img4,      
    },
    {
      original: Img5,
      thumbnail: Img5,      
    },
    {
      original: Img6,
      thumbnail: Img6,      
    },
    {
      original: Img7,
      thumbnail: Img7,      
    },
    {
      original: Img8,
      thumbnail: Img8,      
    },
    {
      original: Img9,
      thumbnail: Img9,      
    },
    {
      original: Img10,
      thumbnail: Img10,      
    },
    {
      original: Img11,
      thumbnail: Img11,      
    },
    {
      original: Img12,
      thumbnail: Img12,      
    },
    {
      original: Img13,
      thumbnail: Img13,      
    },
    {
      original: Img14,
      thumbnail: Img14,      
    },
    {
      original: Img15,
      thumbnail: Img15,      
    },
    {
      original: Img16,
      thumbnail: Img16,      
    },  
  ];
  
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

  return (
    <>
    <Header/>
    <section id="website-responsive" className="container-fluid py-5 border-top border-1 border-white">
      <div className="row justify-content-evenly">
        <div className="col-11 col-md-7 px-0">
        <div className="">
          <div className="" data-aos="fade-up">
          <ImageGallery className="transition" items={images} />
          </div>
          <DetailsOfListing
          Title="TOWNHOUSE FOR SALE IN CASABLANCA BOUTIQUE VILLAS, PACIFICA"
          Title2="Luxurious Urban Retreat | Damac Pacifica"
          Price="1,599,995"
          Location="Dubai"
          Category="Townhouse"
          Beds="3 + Maid"
          Baths="4"
          Area="1,881 sqft / 175 sqm"
          FeaturesAndAmenities={<>
            <FeaturesAndAmenities Title="Central A/C" />
            <FeaturesAndAmenities Title="Balcony" />
            <FeaturesAndAmenities Title="Shared Pool" />
            <FeaturesAndAmenities Title="Security" />
            <FeaturesAndAmenities Title="Covered Parking" />
            <FeaturesAndAmenities Title="Built in Wardrobes" />
            <FeaturesAndAmenities Title="Kitchen Appliances" />
            <FeaturesAndAmenities Title="Shared Gym" />
            <FeaturesAndAmenities Title="Maids Room" />
            <FeaturesAndAmenities Title="Private Garden" />
            <FeaturesAndAmenities Title="Pets Allowed" />
            <FeaturesAndAmenities Title="Children's Pool" />
            <FeaturesAndAmenities Title="Children's Play Area" />
            <FeaturesAndAmenities Title="Barbecue Area" />  
            </>}
          Description={<>
          Townhouse for sale in Casablanca Boutique Villas, Pacifica
          <div className="py-2" />
          Welcome to Damac Pacifica, Madinat Hind 4, a premier residential development by Damac Properties located in the vibrant Madinat Hind community. This prestigious enclave offers an extraordinary blend of elegance, comfort, and modern sophistication, setting a new standard for upscale living in Dubai.
          <div className="py-2" />
          Sophisticated Residences
          <div className="py-2" />
          Damac Pacifica features a selection of meticulously designed 1, 2, and 3-bedroom apartments that exemplify luxury and contemporary style. Each residence is thoughtfully crafted to offer a perfect balance of form and function. Enjoy spacious layouts, high-quality finishes, and modern architectural details that create a harmonious living environment. Floor-to-ceiling windows invite ample natural light and provide stunning views of the dynamic cityscape.
          <div className="py-2" />
          Prime Location
          <div className="py-2" />
          Strategically situated in Madinat Hind, Damac Pacifica provides unmatched connectivity and convenience. Residents benefit from easy access to major roadways, business districts, and popular leisure destinations. The development’s central location ensures proximity to Dubai’s key attractions, upscale shopping centers, and gourmet dining options, placing you at the heart of the city’s vibrant lifestyle.
          <div className="py-2" />
          World-Class Amenities
          <div className="py-2" />
          Damac Pacifica is designed to offer a comprehensive range of world-class amenities that cater to every aspect of modern living. The community features a state-of-the-art fitness center, a relaxing swimming pool, and beautifully landscaped gardens, all aimed at enhancing your quality of life. Dedicated recreational spaces offer opportunities for relaxation and leisure, ensuring a balanced and fulfilling lifestyle.
          <div className="py-2" />
          Discover the pinnacle of contemporary living with Damac Pacifica, Madinat Hind 4. Experience luxury, convenience, and sophistication in one of Dubai’s most desirable locations.

          </>}
          ListingDetailsSection={<>
          <ListingDetailsSection Title1="Property Type" Title2="Townhouse" />          
          <ListingDetailsSection Title1="Property Size" Title2="1,881 sqft / 175 sqm" />          
          <ListingDetailsSection Title1="Bedrooms" Title2="3 + Maid" />          
          <ListingDetailsSection Title1="Bathrooms" Title2="4" />     
          <ListingDetailsSection Title1="Service Charges" Title2="4.27 AED per sqft" />   
          <div className="col-11 col-md-5 d-none d-md-block"/>  
          </>}
          />
      </div>
        </div>
        <div className="col-11 col-md-3">
          <div className="border-white border-1 border p-4 mb-4 sticky-top">
          <InquiryOfListing/>
          <ShareSocialMediaIcons
          url_Email="https://800homes.eacademe.org/townhouse-for-sale-dubai-damac-hills-2-pacifica-casablanca-boutique-VILLAS-12373230"
          url_Whatsapp="https://800homes.eacademe.org/townhouse-for-sale-dubai-damac-hills-2-pacifica-casablanca-boutique-VILLAS-12373230"
          url_Facebook="https://800homes.eacademe.org/townhouse-for-sale-dubai-damac-hills-2-pacifica-casablanca-boutique-VILLAS-12373230"
          url_Linkedin="https://800homes.eacademe.org/townhouse-for-sale-dubai-damac-hills-2-pacifica-casablanca-boutique-VILLAS-12373230"
          />
          </div>
        </div>        
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default Listing3