import React from 'react'

const LeaderReverse = (props) => {

  return (
    <>

    <section className="container-fluid">
      <div className="row justify-content-evenly align-items-center gy-4">
      <div className="col-11 col-md-5 d-block d-md-none pb-4" data-aos="fade-up">
          <img src={props.Img} className='w-100' alt="" />
        </div>
        <div className="col-11 col-md-5" >
        <div className=" text-white fs-ss-18 fw-300 mb-3 lh-1-7em" data-aos="fade-up">
          {props.Para}
        </div>
        <div className="fs-ss-68 fw-400 text-ss-primary text-adistria" data-aos="fade-up">
          {props.Title}
        </div>
        </div>
        <div className="col-11 col-md-5 d-none d-md-block" data-aos="fade-up">
          <img src={props.Img} className='w-100' alt="" />
        </div>
      </div>
    </section>
    
    <section className="py-md-5 py-4" />

    </>
  );
}

export default LeaderReverse;
