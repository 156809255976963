import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import FaraazSiddiquiImg from '../assets/images/Faraz.webp';
import { Helmet } from 'react-helmet';
import ProfileDesktop from '../components/faraaz/ProfileDesktop';
import ProfileMobile from '../components/faraaz/ProfileMobile';


const ProfileFaraazSiddiqui = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>

    <Helmet>
    <meta name="robots" content="noindex"/>
    <meta charSet="utf-8" />
    <title> Faraaz Siddiqui - 800 Homes </title>
    </Helmet>

    <section id="website-responsive" className="d-none d-md-block">
      <ProfileDesktop
      Image={FaraazSiddiquiImg}
      Title="Faraaz Siddiqui"
      Designation="General Manager"
      PhoneNumberLink="+971581129194"
      PhoneNumber="+971 58 112 9194"
      WhatsappLink="971581129194"
      Whatsapp="+971 58 112 9194"
      Email="faraaz@800homes.ae"
      />
    </section>

    <section id="website-responsive" className="d-block d-md-none">
      <ProfileMobile
      Image={FaraazSiddiquiImg}
      Title="Faraaz Siddiqui"
      Designation="General Manager"
      PhoneNumberLink="+971581129194"
      PhoneNumber="+971 58 112 9194"
      WhatsappLink="971581129194"
      Whatsapp="+971 58 112 9194"
      Email="faraaz@800homes.ae"
      />
    </section>

    </>
  );
}

export default ProfileFaraazSiddiqui;
