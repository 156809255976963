import React from "react"
import { EnvelopeFill, GeoAlt, GeoAltFill, TelephoneFill, Whatsapp } from "react-bootstrap-icons";
import {Link} from 'react-router-dom';
import Beds from '../../../assets/images/listing/doublebed.png';
import Shower from '../../../assets/images/listing/shower.png';
import SqFt from '../../../assets/images/listing/ruller.png';
import FeaturesAndAmenities from "./FeaturesAndAmenities";
import ListingDetailsSection from "./ListingDetailsSection";
import SchoolsNearby from "./SchoolsNearby";

const DetailsOfListing = (props) =>{

    return (
    <>
      <div className="text-grey py-5">
        <div className="" data-aos="fade-up">
        <div className="text-grey text-decoration-none fs-ss-30 fw-400 lh-sm">
        <div className="fs-ss-22 fw-300">
        {props.Title} 
        </div>
        <div className="py-3">
        {props.Title2} 
        </div>
        </div>
        </div>
       <div className="py-3">
       <div className="text-uppercase fw-300 fs-ss-35 lh-sm" data-aos="fade-up">
        AED <span className=""> {props.Price} </span>
      </div>
       </div>

        <div className="d-md-flex align-items-center fs-ss-14 fw-300 mt-2 border-top border-bottom border-1 border-white py-4" data-aos="fade-up">
        <div className="fs-ss-16 fw-400 me-3 pb-2 pb-md-0" >
          <GeoAlt className="mb-1"/> {props.Location}
        </div>
        <div className="fs-ss-16 fw-300 me-3 pb-2 pb-md-0">
          {props.Category}
        </div>
          <div className="me-3 pb-2 pb-md-0">
          <img src={Beds} className="listing-icons mb-2" alt="" />
          <span className=""> Beds: </span>           
          <span className=""> {props.Beds} </span> 
          </div>
          
          <div className="me-3 pb-2 pb-md-0">
          <img src={Shower} className="listing-icons mb-2" alt="" />
          <span className=""> Baths: </span>           
          <span className=""> {props.Baths} </span>                    
          </div>
          
          <div className="">
          <img src={SqFt} className="listing-icons me-1 mb-2" alt="" />
          <span className=""> {props.Area} </span>                  
          </div>          
        </div>

        <div className="border-bottom border-1 border-white py-4 py-md-5">
        <div className="text-uppercase fw-300 fs-ss-30 lh-sm pb-4" data-aos="fade-up">
        Features & Amenities
        </div>
        <div className="row justify-content-start fs-ss-18 gx-0" data-aos="fade-up">   
          {props.FeaturesAndAmenities}   
        </div>
        </div>

        <div className="border-bottom border-1 border-white py-4 py-md-5">
        <div className="pb-4">
        <div className="text-uppercase fw-300 fs-ss-30 lh-sm pb-3" data-aos="fade-up">
        Description
        </div>
        <div className="fs-ss-16 fw-300 lh-1-7em" data-aos="fade-up">
          {props.Description}
          <div className="py-2" />
          Kindly Contact Below for More Information
          <div className="" />
          Minhaz Hussin
          <div className="py-2" />
          <span className="text-ss-primary fw-500"> 800 Homes Real Estate Brokerage </span>
          <div className="" />
          <span className=""> Phone: </span> <a href="tel:80046637" className="text-decoration-none text-grey text-hover-ss-primary"> 80046637 </a>
          <div className="" />
          <span className=""> Mobile: </span> <a href="tel:+971585746637" className="text-decoration-none text-grey text-hover-ss-primary"> +971-58-574-6637 </a>
          <div className="" />
          <span className=""> Whatsapp: </span> <a href="https://api.whatsapp.com/send/?phone=971585746637&text&type=phone_number&app_absent=0" className="text-decoration-none text-grey text-hover-ss-primary"> +971-58-574-6637 </a>
          <div className="" />
          {/* <span className=""> Contact: </span> <Link href="/contact-us" className="text-decoration-none text-grey text-hover-ss-primary"> https://800homes.eacademe.org/contact-us </Link> */}
        </div>
        </div>

        <div className="">
        <div className="text-uppercase fw-300 fs-ss-30 pb-4" data-aos="fade-up">
        Listing Details
        </div>
        <div className="row justify-content-evenly fs-ss-18 gx-0 border border-1 border-white fs-ss-16 gy-2 pt-3 pb-4" data-aos="fade-up">
          {props.ListingDetailsSection}     
        </div>
        </div>
        </div>
        
        {/* <div className="border-bottom border-1 border-white py-4 py-md-5">
        <div className="text-uppercase fw-300 fs-ss-30 pb-3" data-aos="fade-up">
        Location
        </div>
        <div className="" data-aos="fade-up">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.1786534495045!2d55.27437639999999!3d25.197197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43348a67e24b%3A0xff45e502e1ceb7e2!2sBurj%20Khalifa!5e0!3m2!1sen!2s!4v1725260365165!5m2!1sen!2s" className="w-100" height="400" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
        </div>
        </div>

        <div className="border-bottom border-1 border-white py-4 py-md-5">
        <div className="text-uppercase fw-300 fs-ss-30 pb-3" data-aos="fade-up">
          Schools Nearby
        </div>
        <div className="row justify-content-center gy-3" data-aos="fade-up">
          <SchoolsNearby/>
          <SchoolsNearby/>
          <SchoolsNearby/>
          <SchoolsNearby/>
          <SchoolsNearby/>
          <SchoolsNearby/>
          <SchoolsNearby/>
          <SchoolsNearby/>
        </div>
        </div> */}

      </div>
    </>
    )
}

export default DetailsOfListing